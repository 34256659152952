import { BillingModel, BillingPeriod, PaywallCalculatedPricePoint, Price } from '@stigg/js-client-sdk';
import { currencyPriceFormatter } from './currencyUtils';
import { PlanPriceText } from './getPlanPrice';
import { calculateTierPrice, getPriceFeatureUnit } from './priceTierUtils';
import { PaywallLocalization } from '../paywall';
import { numberFormatter } from './numberUtils';

type GetPaidPriceTextParams = {
  planPrices: Price[];
  paywallCalculatedPrice?: PaywallCalculatedPricePoint;
  selectedBillingPeriod: BillingPeriod;
  locale: string;
  shouldShowMonthlyPriceAmount: boolean;
  paywallLocale: PaywallLocalization;
  perUnitQuantityByFeature?: Record<string, number>;
};

export function getPaidPriceText({
  planPrices,
  paywallCalculatedPrice,
  selectedBillingPeriod,
  locale,
  shouldShowMonthlyPriceAmount,
  paywallLocale,
  perUnitQuantityByFeature,
}: GetPaidPriceTextParams): PlanPriceText {
  const { amount, currency } = paywallCalculatedPrice || planPrices[0];
  const priceAmount = amount || 0;

  const isSinglePrice = planPrices.length === 1;
  let priceNumber =
    selectedBillingPeriod === BillingPeriod.Annually && shouldShowMonthlyPriceAmount ? priceAmount / 12 : priceAmount;

  let tiers;
  let tierUnits;
  const pricePeriod = paywallLocale.price.pricePeriod(
    shouldShowMonthlyPriceAmount ? BillingPeriod.Monthly : BillingPeriod.Annually,
  );
  let unit = pricePeriod;

  for (const price of planPrices) {
    if (price.isTieredPrice && price.tiers) {
      const quantity = perUnitQuantityByFeature?.[price.feature!.featureId] || 1;
      tiers = price.tiers;
      tierUnits = getPriceFeatureUnit(price);

      priceNumber += calculateTierPrice(price, quantity, selectedBillingPeriod, shouldShowMonthlyPriceAmount);
    }
  }

  if (isSinglePrice) {
    const price = planPrices[0];
    const formattedUnits =
      price.blockSize && price.blockSize > 1
        ? `for ${numberFormatter(price.blockSize)} ${price.feature?.unitsPlural || price.feature?.units || ''}`
        : `per ${price.feature?.units || ''}`;

    if (price.pricingModel === BillingModel.PerUnit && !price.isTieredPrice) {
      unit = `${formattedUnits} ${pricePeriod}`;
    } else if (price.pricingModel === BillingModel.UsageBased) {
      unit = `${formattedUnits}`;
    }
  }

  return {
    price: currencyPriceFormatter({
      amount: priceNumber,
      currency,
      locale,
      minimumFractionDigits: 2,
      removeTrailingZero: true,
    }),
    unit,
    tiers,
    tierUnits,
  };
}
